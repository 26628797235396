<template>
  <HeaderPartial
    title="Statistiques"
  />
  <main>
    <div class="mx-auto max-w-7xl px-6 lg:px-8 mt-10">
      <iframe
        src="https://dashboard.datawb.be/public/dashboard/16dbb126-cb73-4b3a-9965-22029a6dd752"
        frameborder="0"
        width="1200"
        height="3000"
        allowtransparency
      />
    </div>
  </main>
</template>